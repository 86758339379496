import bridge from '@vkontakte/vk-bridge';

// Отправляет событие инициализации нативному клиенту
bridge.send('VKWebAppInit')
    .then((data) => {
        if (data.result) {
            console.log('Приложение инициализировано');
        } else {
            console.log('Произошла ошибка');
        }
    })
    .catch((error) => {
        console.log(error);
    });

// Получаем права доступа
bridge.send('VKWebAppCheckAllowedScopes', { scopes: 'friends,notify' })
    .then((data) => {
        if (data.result) {
            console.log('Права доступа получены');
        }
    })
    .catch((error) => {
        console.log(error);
    });

// Получаем данные пользователя
bridge.send('VKWebAppGetUserInfo')
    .then((data) => {
        if (data.id) {
            // Данные пользователя получены
            fetch('/auth/vk', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    id: data.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    avatar: data.photo_200
                })
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    })
    .catch((error) => {
        console.log(error);
    });



// fetch('/auth/vk', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/json',
//         'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
//     },
//     body: JSON.stringify({
//         id: 12345678,
//         first_name: 'Vova',
//         last_name: 'DVA',
//         avatar: '../../assets/photo_200.png'
//     })
// })
//     .then(response => response.json())
//     .then(response => {
//         console.log(response);
//     })
//     .catch(error => {
//         console.log(error);
//     });
